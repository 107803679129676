@import "~ol/ol.css";
@import "~@ansyn/event-drops/style.css";
@import "bootstrap/carousel.css";
@import 'icons.css';
@import 'tooltip.css';
@import "~primeng/resources/primeng.min.css";
@import "~primeng/resources/themes/omega/theme.css";
@import "~font-awesome/css/font-awesome.min.css";
@import "~cesium/Build/Cesium/Widgets/widgets.css";
@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "~@ansyn/ng-pick-datetime/assets/style/picker.min.css";


circle.drop.selected {
	fill: black;
}


.custom-dialog .mat-dialog-container {
	padding: 0;
	width: 600px;
	overflow: hidden;
	text-align: center;
}

.close.mat-button {
	cursor: pointer;
	float: right;
	padding: 0;
	min-width: 25px;
}

.main-progress .mat-progress-bar-fill::after {
	background-color: #0091ff !important;
}

.main-timeline {
	padding: 0 10px;
	width: 100%;
	margin: 0 auto;
	box-sizing: border-box;
	color: #cbcbcb;
	background-color: #2e2e2e;
}

button {
	cursor: pointer;
	font: unset;
}

button[disabled] {
	cursor: initial;
}

div.pin-location {
	cursor: url('../cursors/pin-location.cur') 12 12, pointer;
}

/* latin */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 300;
	src: local('Open Sans Light'), local('OpenSans-Light'), url(/assets/fonts/Open_Sans/OpenSans-Light.ttf) format('truetype');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	src: local('Open Sans'), local('OpenSans'), url(/assets/fonts/Open_Sans/OpenSans-Regular.ttf) format('truetype');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 600;
	src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url(/assets/fonts/Open_Sans/OpenSans-Semibold.ttf) format('truetype');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 700;
	src: local('Open Sans Bold'), local('OpenSans-Bold'), url(/assets/fonts/Open_Sans/OpenSans-Bold.ttf) format('truetype');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 800;
	src: local('Open Sans Extrabold'), local('OpenSans-Extrabold'), url(/assets/fonts/Open_Sans/OpenSans-ExtraBold.ttf) format('truetype');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin */
@font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 300;
	src: local('Open Sans Light Italic'), local('OpenSansLight-Italic'), url(/assets/fonts/Open_Sans/OpenSans-LightItalic.ttf) format('truetype');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin */
@font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 400;
	src: local('Open Sans Italic'), local('OpenSans-Italic'), url(/assets/fonts/Open_Sans/OpenSans-Italic.ttf) format('truetype');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin */
@font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 600;
	src: local('Open Sans Semibold Italic'), local('OpenSans-SemiboldItalic'), url(/assets/fonts/Open_Sans/OpenSans-SemiboldItalic.ttf) format('truetype');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin */
@font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 700;
	src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'), url(/assets/fonts/Open_Sans/OpenSans-BoldItalic.ttf) format('truetype');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin */
@font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 800;
	src: local('Open Sans Extrabold Italic'), local('OpenSans-ExtraboldItalic'), url(/assets/fonts/Open_Sans/OpenSans-ExtraBoldItalic.ttf) format('truetype');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin */
@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 300;
	src: local('Lato Light'), local('Lato-Light'), url(/assets/fonts/Lato/Lato-Light.ttf) format('truetype');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin */
@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 400;
	src: local('Lato'), local('Lato'), url(/assets/fonts/Lato/Lato-Regular.ttf) format('truetype');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin */
@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 600;
	src: local('Lato Black'), local('Lato-Black'), url(/assets/fonts/Lato/Lato-Black.ttf) format('truetype');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin */
@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 700;
	src: local('Lato Bold'), local('Lato-Bold'), url(/assets/fonts/Lato/Lato-Bold.ttf) format('truetype');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin */
@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 800;
	src: local('Lato Hairline'), local('Lato-Hairline'), url(/assets/fonts/Lato/Lato-Hairline.ttf) format('truetype');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}


/* latin */
@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 800;
	src: local('Lato HairlineItalic'), local('Lato-HairlineItalic'), url(/assets/fonts/Lato/Lato-HairlineItalic.ttf) format('truetype');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin */
@font-face {
	font-family: 'Lato';
	font-style: italic;
	font-weight: 300;
	src: local('Lato Light Italic'), local('LatoLight-Italic'), url(/assets/fonts/Lato/Lato-LightItalic.ttf) format('truetype');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin */
@font-face {
	font-family: 'Lato';
	font-style: italic;
	font-weight: 400;
	src: local('Lato Italic'), local('Lato-Italic'), url(/assets/fonts/Lato/Lato-Italic.ttf) format('truetype');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin */
@font-face {
	font-family: 'Lato';
	font-style: italic;
	font-weight: 600;
	src: local('Lato Black Italic'), local('Lato-BlackItalic'), url(/assets/fonts/Lato/Lato-BlackItalic.ttf) format('truetype');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin */
@font-face {
	font-family: 'Lato';
	font-style: italic;
	font-weight: 700;
	src: local('Lato Bold Italic'), local('Lato-BoldItalic'), url(/assets/fonts/Lato/Lato-BoldItalic.ttf) format('truetype');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

.time-picker-backdrop-undo {
	display: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

@font-face {
	font-family: 'AnSyn';
	src: url('/assets/fonts/AnSyn/AnSyn.eot?56493936');
	src: url('/assets/fonts/AnSyn/AnSyn.eot?56493936#iefix') format('embedded-opentype'),
	url('/assets/fonts/AnSyn/AnSyn.woff?56493936') format('woff'),
	url('/assets/fonts/AnSyn/AnSyn.ttf?56493936') format('truetype'),
	url('/assets/fonts/AnSyn/AnSyn.svg?56493936#AnSyn') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'AnSyn' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	font-size: 24px;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-screen-1:before, .icon-screen-2:before, .icon-screen-3:before, .icon-screen-4:before, .icon-screen-5:before, .icon-screen-6:before {
	font-size: 20px;
	line-height: normal;
}

/deep/ .scrolled::-webkit-scrollbar {
	width: 7px;
}

/deep/ .scrolled::-webkit-scrollbar-corner {
	background-color: transparent;
}

/deep/ .scrolled::-webkit-scrollbar-button {
	width: 0;
	height: 0;
	display: none;
}

/deep/ .scrolled::-webkit-scrollbar-thumb {
	-webkit-box-shadow: inset -1px -1px 0 rgba(0, 0, 0, 0.05), inset 1px 1px 0 rgba(0, 0, 0, 0.05);
	border: 4px solid transparent;
	background-clip: padding-box;
	border-radius: 2px;
	background: white -webkit-gradient(linear, 0 0, 0 100%, color-stop(.5, rgba(255, 255, 255, .2)), color-stop(.5, transparent), to(transparent));
}

.mat-option.mat-active, .mat-option:hover {
	background: rgba(0,0,0,.12) !important;
}

.mat-autocomplete-panel::-webkit-scrollbar, .mat-select-panel::-webkit-scrollbar {
	width: 7px;
}

.mat-autocomplete-panel::-webkit-scrollbar-thumb, .mat-select-panel::-webkit-scrollbar-thumb {
    background: #2c2c2c;
    border-radius: 2px;
}
